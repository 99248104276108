@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  /* margin: 0; */
  border: 0;
}
body {
  margin: 0;
}

/* ------ scroll design ------ */
::-webkit-scrollbar {
  width: 4px;
  background-color: #0f0f10;
}
::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 4px;
}

.fade {
  animation-name: fadeInOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 6s;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
